import { SideBar } from '../../models/sideBar.model';
import { assets } from './assets.const';

export const ModuleConst = {
  Dashboard: 1,
  Users: 2,
  Tiers: 3,
  Offers: 4,
  Categories: 5,
  Brands: 6,
  Stores: 7,
  SubAdmin: 8,
  Notifications: 9,
  CMS: 10
};

export const sideBar: SideBar[] = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    img: assets.DashboardIcon,
    isSubMenu: false,
    id: ModuleConst.Dashboard
  },
  {
    label: 'Manage Users',
    path: '/manage-users',
    img: assets.UsersIcon,
    isSubMenu: false,
    id: ModuleConst.Users
  },
  {
    label: 'Manage Tiers',
    path: '/manage-tiers',
    img: assets.tiersIcon,
    isSubMenu: false,
    id: ModuleConst.Tiers
  },
  {
    label: 'Manage Offers',
    path: '/manage-offers',
    img: assets.offerIcon,
    isSubMenu: false,
    id: ModuleConst.Offers
  },
  {
    label: 'Manage Brand Categories',
    path: '/manage-brand-categories',
    img: assets.CategoryIcon,
    isSubMenu: false,
    id: ModuleConst.Categories
  },
  {
    label: 'Manage Brands',
    path: '/manage-brands',
    img: assets.brandsIcon,
    isSubMenu: false,
    id: ModuleConst.Brands
  },
  {
    label: 'Manage Stores',
    path: '/manage-stores',
    img: assets.CompanyIcon,
    isSubMenu: false,
    id: ModuleConst.Stores
  },
  {
    label: 'Manage Sub-Admin',
    path: '/manage-subadmin',
    img: assets.SubAdminIcon,
    isSubMenu: false,
    id: ModuleConst.SubAdmin
  },
  {
    label: 'Manage Notifications',
    path: '/manage-notifications',
    img: assets.NotificationIcon,
    isSubMenu: false,
    id: ModuleConst.Notifications
  },
  {
    label: 'Manage CMS',
    path: '/manage-cms',
    img: assets.CmsIcon,
    isSubMenu: false,
    id: ModuleConst.CMS
  }
];
